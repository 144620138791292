<template>
  <b-modal
    class="bmodal"
    size="sm"
    id="addNonMemberModal"
    ref="modalAddNonMember"
    :title="modal_title"
    centered
    @ok="addNewNonMember($event)"
    @cancel="handleCancelClick()"
    @show="handleShow()"
    :ok-title="i18n.tcOk"
    :cancel-title="i18n.tcCancel"
    :ok-disabled="!validData"
  >
    <div>
      <form ref="form">
        <b-container fluid class="text-light text-left">
          <div class="pt-2">
            <b-form>
              <b-form-group
                class="form-element"
                :label="i18n.components && i18n.components['conversation-add-non-member'].tcFirstName"
                label-for="first-name-input"
              >
                <b-form-input
                  id="first-name-input"
                  :placeholder="i18n.components && i18n.components['conversation-add-non-member'].tcFirstName"
                  v-model="form.firstName"
                  :state="form.firstName !== null && form.firstName !== ''"
                  required
                  :trim="formTrim"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="form-element"
                :label="i18n.components && i18n.components['conversation-add-non-member'].tcLastName"
                label-for="last-name-input"
              >
                <b-form-input
                  id="last-name-input"
                  :placeholder="i18n.components && i18n.components['conversation-add-non-member'].tcLastName"
                  v-model="form.lastName"
                  :state="form.lastName !== null && form.lastName !== ''"
                  required
                  :trim="formTrim"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="form-element"
                :label="i18n.components && i18n.components['conversation-add-non-member'].tcPhone"
                label-for="phone-input"
              >
                <b-form-input
                  id="phone-input"
                  :placeholder="i18n.components && i18n.components['conversation-add-non-member'].tcPhone"
                  type="tel"
                  v-model="form.phone"
                  :trim="formTrim"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="form-element"
                :label="i18n.components && i18n.components['conversation-add-non-member'].tcEmail"
                label-for="email-input"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  id="email-input"
                  type="email"
                  :placeholder="i18n.components && i18n.components['conversation-add-non-member'].tcEmail"
                  v-model="form.email"
                  :trim="formTrim"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </div>
        </b-container>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import constantData from '@/json/data.json'
import { Loader } from '@/mixins/Loader'

export default {
  name: 'conversation-add-non-member',
  mixins: [Loader],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          components: {
            'conversation-add-non-member': {
              tcAddNonMember: 'Add Non-Member',
              tcCancel: 'Cancel',
              tcOk: 'Ok',
              tcOperationWasSuccessful: 'Operation was successful',
              tcFirstName: 'First Name',
              tcLastName: 'Last Name',
              tcPhone: 'Phone',
              tcEmail: 'Email'
            }
          }
        }
      }
    },
    emailRequired: {
      type: Boolean,
      default: false
    },
    modal_title: {
      type: String,
      default: null
    },
    phoneRequired: {
      type: Boolean,
      default: false
    },
    phoneOrEmailRequired: {
      type: Boolean,
      default: false
    },
    cnt_key: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      form: {
        firstName: null,
        lastName: null,
        phone: null,
        email: null
      },
      formTrim: true,
      isDisabled: true,
      originalData: null,
      result: {},
      state: null
    }
  },
  methods: {
    ...mapActions({
      addNonMember: 'conversations/addNonMember'
    }),
    async addNewNonMember() {
      if (this.validData) {
        let postNonMember = {
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          phone: this.form.phone,
          email: this.form.email,
          ind_user: this.demograph.ind_key,
          cnt_key: this.cnt_key
        }
        this.result = {}
        this.showLoader()
        await this.addNonMember(postNonMember)
          .then(res => {
            if (res.status === 200) {
              this.$emit('selectionMade', res.data)
              this.message(
                this.i18n.components['conversation-add-non-member'].tcOperationWasSuccessful,
                'success',
                this.i18n.tcOk
              )
              this.closeLoader()
            }
          })
          .catch(err => {
            this.message()
            this.closeLoader()
          })
      } else {
        return
      }
    },
    handleCancelClick() {
      this.clearForm()
    },
    async handleShow() {
      this.clearForm()
    },
    closeModal() {
      this.clearForm()
      this.$refs.modalAddNonMember.hide()
    },
    clearForm() {
      this.form.firstName = null
      this.form.lastName = null
      this.form.phone = null
      this.form.email = null
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
      demograph: 'user/demograph'
    }),
    USA_country() {
      return constantData.USA_country
    },
    validData() {
      let isValidated = true
      if (
        !this.form.firstName ||
        (this.form.firstName && this.form.firstName.length === 0) ||
        !this.form.lastName ||
        (this.form.lastName && this.form.lastName.length === 0)
      ) {
        isValidated = false
      }
      if (this.phoneRequired && (!this.form.phone || (this.form.phone && this.form.phone.length === 0))) {
        isValidated = false
      }
      if (this.emailRequired && (!this.form.email || (this.form.email && this.form.email.length === 0))) {
        isValidated = false
      }
      if (this.phoneOrEmailRequired && 
        ((!this.form.email || (this.form.email && this.form.email.length === 0))
          && (!this.form.phone || (this.form.phone && this.form.phone.length === 0)))
      ) {
        isValidated = false
      }
      return isValidated
    }
  },
  watch: {
    form: {
      handler(netval, oldval) {
        this.isDisabled = Object.keys(this.form).some(item => {
          return this.form[item] === null || this.form[item] === ''
        })
      }
    }
  },
  async created() {}
}
</script>

<style lang="scss" scoped></style>
